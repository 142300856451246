
import { defineComponent } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "Widget1",
  components: {
    Dropdown3
  },
  setup() {
    const options = {
      chart: {
        type: "donut",
        fontFamily: "inherit"
      },
      plotOptions: {
        pie: {
          customScale: 0.94,
          expandOnClick: false,
          donut: {
            size: "75%"
          }
        }
      },
      labels: ["Angular", "CSS", "HTML"],
      colors: ["#fec703", "#00a3ff", "#22d48a"],
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      animation: {
        animateScale: true,
        animateRotate: true
      }
    };

    const series = [30, 40, 25];

    return {
      options,
      series
    };
  }
});
