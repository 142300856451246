
import { defineComponent, ref } from "vue";
import Dropdown4 from "@/view/content/dropdown/Dropdown4.vue";

export default defineComponent({
  name: "Widget2",
  components: {
    Dropdown4
  },
  setup() {
    const series1 = ref([
      {
        name: "Net Profit",
        data: [44, 55, 57, 56, 61, 58]
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105]
      }
    ]);

    const series2 = ref([
      {
        name: "Net Profit",
        data: [35, 60, 35, 50, 45, 30]
      },
      {
        name: "Revenue",
        data: [65, 80, 50, 80, 75, 105]
      }
    ]);

    const series3 = ref([
      {
        name: "Net Profit",
        data: [25, 40, 45, 50, 40, 60]
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105]
      }
    ]);

    const series4 = ref([
      {
        name: "Net Profit",
        data: [50, 35, 45, 55, 30, 40]
      },
      {
        name: "Revenue",
        data: [76, 85, 101, 98, 87, 105]
      }
    ]);

    const chartOptions = ref({
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["40%"],
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: "#A1A5B7",
            fontSize: "12px"
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: "#A1A5B7",
            fontSize: "12px"
          }
        }
      },
      fill: {
        opacity: 1
      },
      colors: ["#20D489", "#E4E6EF"],
      ttooltip: {
        style: {
          fontSize: "12px"
        },
        y: {
          formatter: val => {
            return "$" + val + " thousands";
          }
        }
      },
      grid: {
        borderColor: "#EFF2F5",
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    });

    return {
      chartOptions,
      series1,
      series2,
      series3,
      series4
    };
  }
});
