import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xxl-8" }
const _hoisted_2 = { class: "col-xl-4" }
const _hoisted_3 = { class: "col-xl-8" }
const _hoisted_4 = { class: "row g-5 g-xxl-8" }
const _hoisted_5 = { class: "col-xl-4" }
const _hoisted_6 = { class: "col-xl-8" }
const _hoisted_7 = { class: "row g-5 g-xxl-8" }
const _hoisted_8 = { class: "col-xl-4" }
const _hoisted_9 = { class: "col-xl-8" }
const _hoisted_10 = { class: "row g-5 g-xxl-8" }
const _hoisted_11 = { class: "col-xl-4" }
const _hoisted_12 = { class: "col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneralWidget1 = _resolveComponent("GeneralWidget1")!
  const _component_TableWidget1 = _resolveComponent("TableWidget1")!
  const _component_StatsWidget1 = _resolveComponent("StatsWidget1")!
  const _component_StatsWidget2 = _resolveComponent("StatsWidget2")!
  const _component_ListWidget9 = _resolveComponent("ListWidget9")!
  const _component_TableWidget2 = _resolveComponent("TableWidget2")!
  const _component_StatsWidget7 = _resolveComponent("StatsWidget7")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_GeneralWidget1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TableWidget1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_StatsWidget1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_StatsWidget2)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ListWidget9)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_TableWidget2)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ListWidget9)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_StatsWidget7)
      ])
    ])
  ], 64))
}