
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "dropdown-4",
  components: {},
  setup() {
    onMounted(() => {
      // Lazy Initialization
      MenuComponent.reinitialization();
    });
  }
});
