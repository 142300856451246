
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "widget-2",
  components: {},
  setup() {
    const day = ref([
      {
        image: "media/svg/avatars/001-boy.svg",
        name: "Brad Simmons",
        desc: "HTML, CSS Coding",
        paid: "$1,200,000",
        profit: "+28%",
        status: "primary"
      },
      {
        image: "media/svg/avatars/018-girl-9.svg",
        name: "Jessie Clarcson",
        desc: "Most Successful",
        paid: "$1,200,000",
        profit: "+52%",
        status: "danger"
      },
      {
        image: "media/svg/avatars/047-girl-25.svg",
        name: "Lebron Wayde",
        desc: "Awesome Users",
        paid: "$3,400,000",
        profit: "+34%",
        status: "warning"
      },
      {
        image: "media/svg/avatars/043-boy-18.svg",
        name: "Kevin Leonard",
        desc: "Awesome Userss",
        paid: "$35,600,000",
        profit: "+230%",
        status: "success"
      },
      {
        image: "media/svg/avatars/024-boy-9.svg",
        name: "Randy Trent",
        desc: "Business Analyst",
        paid: "$45,200,000",
        profit: "+340%",
        status: "info"
      }
    ]);
    const week = ref([
      {
        image: "media/svg/avatars/024-boy-9.svg",
        name: "Randy Trent",
        desc: "Business Analyst",
        paid: "$45,200,000",
        profit: "+340%",
        status: "info"
      },
      {
        image: "media/svg/avatars/001-boy.svg",
        name: "Brad Simmons",
        desc: "HTML, CSS Coding",
        paid: "$1,200,000",
        profit: "+28%",
        status: "primary"
      },
      {
        image: "media/svg/avatars/018-girl-9.svg",
        name: "Jessie Clarcson",
        desc: "Most Successful",
        paid: "$1,200,000",
        profit: "+52%",
        status: "danger"
      },
      {
        image: "media/svg/avatars/047-girl-25.svg",
        name: "Lebron Wayde",
        desc: "Awesome Users",
        paid: "$3,400,000",
        profit: "+34%",
        status: "warning"
      },
      {
        image: "media/svg/avatars/043-boy-18.svg",
        name: "Kevin Leonard",
        desc: "Awesome Userss",
        paid: "$35,600,000",
        profit: "+230%",
        status: "success"
      }
    ]);
    const month = ref([
      {
        image: "media/svg/avatars/018-girl-9.svg",
        name: "Jessie Clarcson",
        desc: "Most Successful",
        paid: "$1,200,000",
        profit: "+52%",
        status: "danger"
      },
      {
        image: "media/svg/avatars/047-girl-25.svg",
        name: "Lebron Wayde",
        desc: "Awesome Users",
        paid: "$3,400,000",
        profit: "+34%",
        status: "warning"
      },
      {
        image: "media/svg/avatars/043-boy-18.svg",
        name: "Kevin Leonard",
        desc: "Awesome Userss",
        paid: "$35,600,000",
        profit: "+230%",
        status: "success"
      },
      {
        image: "media/svg/avatars/024-boy-9.svg",
        name: "Randy Trent",
        desc: "Business Analyst",
        paid: "$45,200,000",
        profit: "+340%",
        status: "info"
      },
      {
        image: "media/svg/avatars/001-boy.svg",
        name: "Brad Simmons",
        desc: "HTML, CSS Coding",
        paid: "$1,200,000",
        profit: "+28%",
        status: "primary"
      }
    ]);

    return {
      day,
      week,
      month
    };
  }
});
